<script>
  import Heading from "../components/Heading.svelte"
</script>

<style>
  p {
    margin: 1rem auto 2rem;
    text-align: center;
  }

  img {
    display: block;
    width: 50%;
    max-width: 300px;
    margin: 0 auto;
  }
</style>

<Heading>Operation cancelled</Heading>
<p>Nothing happened. Go back to the app.</p>
<img src="assets/sadDog.svg" alt="" />
