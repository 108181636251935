<script>

</script>

<style>
  header {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--primary);
    padding: 0.5rem;
  }

  header img {
    display: block;
    width: auto;
    height: 2.5rem;
  }
</style>

<header>
  <img src="/assets/logo-white.svg" alt="" />
</header>
