<script>
  import Header from "./Header.svelte"
</script>

<Header />
<main>
  <slot />
</main>

<style>
  main {
    padding: 1em;
    max-width: 400px;
    margin: 0 auto;
  }
  @media (min-width: 640px) {
    main {
      max-width: none;
      overflow: hidden;
    }
  }
</style>
