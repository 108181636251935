<script>

</script>

<style>
  @keyframes zoomInOut {
    0%,
    100% {
      transform: scale(1);
      opacity: 1;
      color: var(--primary);
    }
    50% {
      transform: scale(0.7);
      opacity: 0.6;
      color: var(--yellow);
    }
  }

  section {
    width: 100%;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  h1 {
    text-transform: uppercase;
    font-size: 3rem;
    font-weight: 100;
    animation: zoomInOut 2s linear infinite;
  }
</style>

<section>
  <h1>Loading...</h1>
</section>
