<style>
  h1 {
    text-align: center;
    text-transform: uppercase;
    font-size: 2.7rem;
    font-weight: 100;
  }

  @media (max-width: 340px) {
    h1 {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }
  }
</style>

<h1>
  <slot />
</h1>
